<template>
  <!-- <el-tabs tab-position="left" style="height: 100%;" type="border-card" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="银行" name="first">
      <bank ref="bank"></bank>
    </el-tab-pane>
    <el-tab-pane label="现金" name="second">
      <cash ref="cash"></cash>
    </el-tab-pane>
    <el-tab-pane label="票据" name="third">
      <bill ref="bill"></bill>
    </el-tab-pane>
  </el-tabs> -->

  <qzf-tabs v-model:value="value" @tab-click="handleClick">
    <qzf-tabs-item label="银行" name="1" :cache="false">
      <bank ref="bank"></bank>
    </qzf-tabs-item>
    <qzf-tabs-item label="现金" name="2" :cache="false">
      <cash ref="cash"></cash>
    </qzf-tabs-item>
    <qzf-tabs-item label="票据" name="3" :cache="false">
      <bill ref="bill"></bill>
    </qzf-tabs-item>
  </qzf-tabs>
</template>

<script>
import bank from "./components/bank.vue"
import cash from "./components/cash.vue"
import bill from "./components/bill.vue"
export default {
  name: "money",
  components:{ bank, cash, bill },
  data () {
    return {
      value:'1',
    }
  },
  deactivated(){
    if(!this.$getCachedViews("money")){//销毁 mitt bus
      this.$bus.off("bankUpdate")
      this.$bus.off("cashUpdate")
      this.$bus.off("billUpdate")
    }
  },
  // watch: {
  //   $route() {
  //     if(this.$route.path == "/input/invoice"){
  //       console.log("从发票模块跳过来的",this.$route);
  //       this.changeRouter()
  //     }
  //     if(this.$route.path != "/input/money" || !this.$route.params){
  //       return
  //     }
  //   }
  // },
  created(){
    this.initBus()
    // this.changeRouter()
  },
  // beforeRouteLeave(to, from, next) {
  //   console.log(to,from,next,"money");
  // },
  methods:{
    // 批量结算跳转路由
    // changeRouter(){
    //   if(this.$route.params.routeSource == 'bank'){
    //     this.value = '1'
    //   }else if (this.$route.params.routeSource == 'cash'){
    //     this.value = '2'
    //   }
    // },
    initBus(){
      this.$bus.on("bankUpdate", (val) => {
        if(this.$refs.bank){
          this.$refs.bank.getList()
        }
      });
      this.$bus.on("cashUpdate", (val) => {
        if(this.$refs.cash){
          this.$refs.cash.getList()
        }
      });
      this.$bus.on("billUpdate", (val) => {
        if(this.$refs.bill){
          this.$refs.bill.getList()
        }
      });
    }
  }
}
</script>

<style>

</style>