<template>
  <div class="app-container">
    <section class="top_btns clearfix">
      <div class="piliang-container">
        <qzf-video vid="4467be537471bba44353014f4c7fa46c_4"></qzf-video>

        <span style="color:#F15A24;font-size:15px;">余额:</span>
        <span style="color:#F15A24;font-size:15px;margin-right:10px">{{cashAmount}}</span>
        <qzf-button button_code="zj_xj_tj" size='small' type="primary" @success="handleAdd"   >
          <el-icon><CirclePlus /></el-icon><span  > 添加</span>
        </qzf-button>
        <qzf-button size="small" type="danger" @success="delAll()" :loading="deleteLoding"   >
          <el-icon><Delete /></el-icon><span  > 批量删除</span>
        </qzf-button>
        <qzf-button button_code="zj_xj_bc" size='small' type="success" @success="saveAll"   >
          <el-icon><Finished /></el-icon><span  > 批量保存</span>
        </qzf-button>
        <settings  :type="['code_cash_1001']"></settings>
        <el-dropdown @command="handleCommand" size="small" style="margin-left:10px">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a">
                <span>一键生成凭证</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </section>

    <el-table stripe :height="contentStyleObj" @selection-change="handleSelectionChange" :data="list" style="width: 100%" :show-header='true' border fit highlight-current-row :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}">
      
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="40" align="center" style="padding-left:0;" />
      <el-table-column label="日期" align="center" width="150">
        <template #default="scope">
            <el-date-picker style="width: 100%" :disabled="!scope.row.edit" v-model="scope.row.transDate" type="date" placeholder="选择日期" size="small"></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="方向" align="center" min-width="150">
        <template #default="scope">
          <span style="padding-left:10px; color:green" v-if="scope.row.type == 1">收入</span>
          <span style="padding-left:10px; color:red" v-if="scope.row.type == 2">支出</span>
        </template>
      </el-table-column>
      <el-table-column label='业务类型' align="center" min-width="320">
        <template #default="scope">
          <div class="block">
            <business-type v-model:businessId="scope.row.businessTypeId" v-model:itemId="scope.row.subjectId" v-model:fzhsId="scope.row.fzhsId" v-model:szbz="scope.row.type" v-model:businessTypeName="scope.row.businessTypeName" type="cash" :disabledIn="!scope.row.edit"></business-type>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="金额" align="center" min-width="150">
        <template #default="scope" label='金额'>
          <qzf-input :disabled="!scope.row.edit"  v-model:num="scope.row.amount" size="small" />
        </template>
      </el-table-column>
      <el-table-column label="凭证" align="center" min-width="150">
        <template #default="scope" label='凭证'>
          <span v-if="scope.row.VoucherNo" @click="getHandlePingzheng(scope.row.voucherId)" style="color:#39b0d2;text-decoration:underline;cursor:pointer">{{ scope.row.VoucherNo }}</span>
          <span v-else @click="showVoucher(scope.row)" style="color:#39b0d2;text-decoration:underline;cursor:pointer">生成凭证</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="top_right_btns" min-width="200">
        <template #default="scope">
          <el-tooltip content="保存" placement="top">
            <span>
              <i class="iconfont icon-baocun" @click="handleSave(scope.row)" v-if="scope.row.edit"></i>
            </span>
          </el-tooltip>
          <el-tooltip content="编辑" placement="top">
            <span>
              <i class="iconfont icon-bianji" @click="handleEdit(scope.row)" v-if="!scope.row.edit"></i>
            </span>
          </el-tooltip>
          <el-tooltip content="删除" placement="top">
            <span>
              <i class="iconfont icon-shanchu" @click="handleDelete(scope.row,scope.$index)"></i>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <div class="bottom_style">
      <div class="bottom_button">
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
    <voucher-show ref="voucherShow" from="4" @success="getList"></voucher-show>
  </div>
</template>

<script>
import { saveCash , delCash , cashList } from "@/api/cash"
import { getPeriodTime } from "@/utils/fun";
import { oneKeyVoucher } from '@/api/voucher'
export default {
  name:'cash',
  data() {
    return {
      cashAmount: 0,
      index: "", //大项
      $index: "", //小项
      periodAmount: "",
      fromInput: null,
      list: [], // 现金列表
      //联级选择器的业务数据
      options: [],
      listQuery: {
        page: 1,
        limit: 20
      },
      total:2,
      allDelSelect:[],
      allSaveSelect:[],
      // fromInput: null, // 非空表示是从发票录入的结算而来, 注意:一定要在适当的时候重置为null (其它跳转至此页面, 结算产生的记录保存成功之后,...)

    }
  },
  created() {
    // this.init()
    this.getList()
    this.contentStyleObj = this.$getHeight(240)
  },
  // watch: {
  //   $route() {
  //     if(this.$route.path != "/input/money" || !this.$route.params){
  //       return
  //     }
  //     this.getList();
  //   }
  // },
  methods:{
    // init(){
    //   this.fromInput = this.$route.params.settleType;
    // },
    handleAdd() {
      this.list.unshift({
        amount: 0,
        transDate: getPeriodTime(),
        edit: true,
        remark: "转账摘要(备注)"
      });
    },
    handleSave(item){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      saveCash([item]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')

          item.edit = false
          this.getList()
        }
      })
    },
    handleEdit(item) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      item.edit = true
    },
    handleDelete(item, index) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(!item.id){
        this.list.splice(index, 1);
        return
      }
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCash([{ id: item.id }]).then(res => {
          if(res.data.msg == 'success'){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.getList();
          }
        });
      })
    },
    getList(){
      cashList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total
          this.cashAmount = res.data.data.endAmount
        }else{
          this.list = []
        }
        console.log(this.fromInput);
        // if(this.fromInput){
        //   let settleData = JSON.parse(this.$route.params.settleData)
        //   if(this.$route.params.settleType == 'output'){
        //     // 销项
        //     settleData.map(v => {
        //       this.list.unshift({
        //         amount: v.sumAmount,
        //         businessTypeId:3462,
        //         subjectId:v.subjectId,
        //         fzhsId:v.fzhsId,
        //         transDate: getPeriodTime(),
        //         edit: true,
        //         remark: "收销售款",
        //         type:1
        //       });
        //     })
        //     console.log(this.list);
        //   }else if(this.$route.params.settleType == 'income'){
        //     // 进项  todo
        //     settleData.map(v => {
        //       this.list.unshift({
        //         amount: v.sumAmount,
        //         businessTypeId:3462,
        //         subjectId:v.subjectId,
        //         fzhsId:v.fzhsId,
        //         transDate: getPeriodTime(),
        //         edit: true,
        //         remark: "收销售款",
        //         type:1
        //       });
        //     })
        //   }
        // }
      })
    },
    handleSelectionChange(val) {
      //多选选完之后在这
      let delArr = [];
      let saveArr = []
      val.map(v => {
        delArr.push({id:v.id});
      });
      val.map(v => {
        saveArr.push(v);
      });
      this.allDelSelect = delArr;
      this.allSaveSelect = saveArr
    },
    delAll(){
      if(this.allDelSelect.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$confirm(`确认删除${this.allDelSelect.length}条票据么? `, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCash(this.allDelSelect).then(res => {
          if(res.data.msg != 'success'){
            return
          }
          this.$qzfMessage("删除成功")
          this.$bus.emit('voucherUpdate')
          this.getList();
        });
      })
    },
    saveAll(){
      if(this.allSaveSelect.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      saveCash(this.allSaveSelect).then(res=>{
        if(res.data.msg == "success"){
          this.allSaveSelect.map(v=>{
            v.edit = false
          })
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')

          this.getList();
        }
      })
    },
    showVoucher(item){
      this.$refs.voucherShow.getList(item)
    },
    getHandlePingzheng(voucherId) {
      this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    handleCommand(command) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(command == 'a'){
        oneKeyVoucher({type:'cash'}).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("操作成功")
            this.$bus.emit('voucherUpdate')
            this.getList()
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.piliang-container {
  float: right;
}
.bank-table-header {
  background: #eee;
  position: relative;
  border: 1px solid #c2c2c2;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  box-sizing: border-box;
  overflow: hidden;
  height: 36px;
  line-height: 34px;
  font-size: 14px;
  > div {
    float: left;
    border-right: 1px solid #c2c2c2;
    text-align: center;
    box-sizing: border-box;
    width: calc((100%) / 6);
  }
   > div:last-child{
    border-right: 0;
  }
  input {
    position: absolute;
    zoom: 120%;
    left: 5px;
    top: 50%;
    transform: translateY(-50%) !important;
  }
}
.top_right_btns {
  float: right;
  margin-right: 15px;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: $color-main;
    font-size: 18px;
    line-height: 24px;
  }
}
 
.pagination{
  text-align: right;
  margin-right: 26px;
  margin-top: 16px;
}
.top_btns{
  margin-bottom: 10px;
}
</style>