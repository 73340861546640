import request from '@/utils/request'

export function saveBill(data) {
  return request({
    url: 'api/v2/bill/saveBill',
    method: 'post',
    data
  })
}

export function delBill(data) {
  return request({
    url: 'api/v2/bill/delBill',
    method: 'post',
    data
  })
}

export function billList(data) {
  return request({
    url: 'api/v2/bill/billList',
    method: 'post',
    data
  })
}