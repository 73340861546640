import request from '@/utils/request'

export function saveCash(data) {
  return request({
    url: 'api/v2/cash/saveCash',
    method: 'post',
    data
  })
}

export function delCash(data) {
  return request({
    url: 'api/v2/cash/delCash',
    method: 'post',
    data
  })
}

export function cashList(data) {
  return request({
    url: 'api/v2/cash/cashList',
    method: 'post',
    data
  })
}