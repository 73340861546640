<template>
  <div class="app-container">
    <div style="position:absolute;top:22px;z-index: 99;right: 20px;">
      <span style="color:#39b0d2">
        <span style="margin-right:10px">数量:{{total}}笔</span>
        <span style="margin-right:10px">本期收入:{{bankAmountIn}}元</span>
        <span style="margin-right:10px">本期支出:{{bankAmountOut}}元</span>
        <span style="margin-right:10px">余额:{{bankAmount}}元</span>
      </span>
      <qzf-video vid="4467be537429abb985e9fca524dae46f_4"></qzf-video>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">

      <el-tab-pane label="全部" name="first"></el-tab-pane>
      <el-tab-pane label="未补充" name="second"></el-tab-pane>
      <el-tab-pane label="已补充" name="third"></el-tab-pane>

      <!-- 顶部搜索区域和按钮 -->
      <div class="top_btns">
        <div class="left_box">
          <span style="margin-left:10px;">银行账户：</span>
          <el-select placeholder="请选择" v-model="bankNameId" @change='changeBank' size="small" filterable style="width:120px">
            <el-option v-for="(item,index) in bankList " :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <search @success="getList">
            <el-form style="margin:11px 0px 0px 6px;width:400px;" class="styleForm">
              <el-form-item label=" 账户分类：" :label-width="formLabelWidth">          
                <span style="margin-left:10px;">{{ accountStatus }}账户 </span>
              </el-form-item>
              <el-form-item label="科目 :" :label-width="formLabelWidth">
                <subject-list :addNotShow="true" v-model:subjectId="listQuery.subjectId" v-model:fzhsItemId="listQuery.fzhsId"></subject-list>
              </el-form-item>
              <el-form-item label="对方账户名 :" :label-width="formLabelWidth">
                <el-input style="width:200px" size="small" placeholder="对方账户名" v-model="listQuery.otherName"></el-input>
              </el-form-item>
              <el-form-item label="类别 :" :label-width="formLabelWidth">
                <el-select placeholder="请选择" size="small" v-model="listQuery.typeAmount">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="收入" :value="1"></el-option>
                  <el-option label="支出" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </search>
        </div>
        <div class="right_box">

         

          <el-dropdown split-button type="success" @click="handleAddBig" size="small" style="margin-right:10px" :hide-on-click="false">
            <i class="iconfont icon-jiahao"></i> 添加
            <template #dropdown>
              <el-dropdown-menu class="xxx">
                <el-dropdown-item>
                  <span size="small" @click="openCom">银行导入</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <qzf-button type="warning" @click="collectBank">
            <el-icon><Aim /></el-icon><span> 采集</span>
          </qzf-button>
          <qzf-button size='small' type="primary" @success="handleCzmuch"   >
            <el-icon><Memo /></el-icon><span  > 批量补充</span>
          </qzf-button>
          <qzf-button size="small" type="danger" @success="handleDeleteAll()">
            <el-icon><Delete /></el-icon><span  > 批量删除</span>
          </qzf-button>
          <el-dropdown @command="handleCommand" size="small" style="margin-left:10px">
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a">
                <span>一键生成凭证</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        </div>
      </div>
    
    <!-- 导入 -->

    <div class="table_height"  ref="item" style="margin-top:10px">
      <section class="bank-table-header" v-if="list.length>0">
        <input type="checkbox" @click="allSelect" :checked='isAllSelected'/>
        <div>日期</div>
        <div>业务类型</div>

        <div v-if="accountStatus != '人民币' && accountStatus != '' && accountStatus">原币金额</div>
        <div v-if="accountStatus != '人民币' && accountStatus != '' && accountStatus">汇率</div>  

        <div>本位币金额</div>
        <div>备注</div>
        <div>操作</div>

      </section>
        
        <!-- 一大条 -->
      <div class="table_height" @scroll="scrollEvent" :style="{height:contentStyleObj}" v-loading="isLoading">
        <el-empty :image-size="150" v-if="this.list.length == 0"></el-empty>

        <section class="bank-item-container" v-for="(item,index) in list" :key="index">
          <section class="bank-item-header">
            <div class="ccyc">
              <input type="checkbox" :checked="item.checked" @click="singleSelect(item, index)"/>
            </div>
            <div class="ccyc">
              {{ bankName }}
            </div>
            <el-tooltip effect="dark" :content="item.otherName" placement="top">
              <div class="ccyc" style="width:315px">
                对方账户名称:<input v-if="item.show || this.activeName == 'second'" type="text" class="dfyhm" v-model="item.otherName" style="width:220px">
                <span v-else>{{item.otherName}}</span>
              </div>
            </el-tooltip>
            <div style="color:red;width:18%" v-if="item.amount < 0" class="ccyc">
              收入金额(本位币): {{ item.amount }}
            </div>
            <div v-else style="width:18%" class="ccyc">
              收入金额(本位币): {{ item.amount }}
            </div>
            <div class="ccyc" style="width:106px">
              凭证号:
              <span style="color:#3c6ff2;text-decoration:underline;cursor:pointer;font-weight:600" v-if="item.voucherNo" @click="getHandlePingzheng(item.voucherId)">{{item.voucherNo}}</span>
              <span style="color:#3c6ff2;cursor:pointer" v-else @click="showVoucher(item)">生成凭证</span>
            </div>
            <div class="ccyc">
              
            </div>
            <!-- 编辑，删除时候出现的保存，删除按钮 -->
            <div class="bank-item-header-caozuo"  v-if="item.show || this.activeName == 'second'">
              <el-tooltip content="保存" placement="top">
                <i class="iconfont icon-baocun" @click="handleSave(item)" style="color:#3c6ff2"></i>
              </el-tooltip>
            </div>
            <!-- 鼠标经过时出现的删除以及其他按钮 -->
            <div class="bank-item-header-caozuo">
              <el-tooltip content="查看电子回单" placement="top">
                <el-button style="padding:0px;padding-bottom:6px" type="text" size="large" @click="checkBankPdf(item)" :disabled ="item.from != 'collect'">
                  <i class="iconfont icon-lianjie"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top">
                <i class="iconfont icon-shanchu" @click="handleDeleteBig(item)" style="color:#3c6ff2"></i>
              </el-tooltip>
            </div>
          </section>
          <!-- 银行账期的选择 -->
          <!-- 一小条 -->
          <table class="item_box" cellspacing="0" width="100%">
            <tr v-for="(bankItem,index2) in item.items" :key="index2">
              <td>
                <div v-if="item.edit || this.activeName == 'second'">
                  <el-date-picker style="width:100%;" type="date" v-model="bankItem.transDate" :clearable="false" placeholder="选择日期" size="small"></el-date-picker>
                </div>
                <span v-else style="padding-left:10px;">{{ $parseTime(bankItem.transDate, "{y}-{m}-{d}") }}</span>
              </td>
              <td style="min-width:0">
                <div v-if="item.edit || this.activeName == 'second'">
                  <div class="block">
                    <business-type :name="item.otherName" :direction="getDirection(item)" v-model:businessId="bankItem.businessTypeId" v-model:itemId="bankItem.subjectId" v-model:fzhsId="bankItem.fzhsId" v-model:szbz="bankItem.type" v-model:businessTypeName="bankItem.summary" type="bank"></business-type>
                  </div>
                </div>
                <div v-else>
                  <el-tooltip effect="dark" :content="bankItem.businessTypeName+'-'+bankItem.subjectName" placement="top">
                    <div class="ccyc">
                      <span style="padding-left:10px;">{{bankItem.businessTypeName}}-</span>
                      <span>{{bankItem.subjectName}}</span>
                      <span v-if="bankItem.fzhsName">-{{bankItem.fzhsName}}</span>
                    </div>
                  </el-tooltip>
                  
                </div>
              </td>
              <td v-if="accountStatus != '人民币' && accountStatus != '' && accountStatus">
                <qzf-input v-if="item.edit || this.activeName == 'second'" size="small"  @blur="changeWb(bankItem)" v-model:num="bankItem.wbAmount"/>
                <span v-else style="margin-left: 10px">{{ Math.abs(bankItem.wbAmount )}}</span>
              </td>
              <td v-if="accountStatus != '人民币' && accountStatus != '' && accountStatus">
                <qzf-input v-if="item.edit || this.activeName == 'second'"  @blur="changeWb(bankItem)" size="small" v-model:num="bankItem.rate"/>
                <span v-else style="margin-left: 10px">{{ Math.abs(bankItem.rate )}}</span>
              </td>
              <td>
                <qzf-input v-if="item.edit || this.activeName == 'second'"  size="small"  v-model:num="bankItem.amount"/>
                <span v-else style="margin-left: 10px">{{ Math.abs(bankItem.amount )}}</span>
              </td>
              <td>
                <el-input v-if="item.edit || this.activeName == 'second'" size="small" type="text" v-model="bankItem.summary"/>
                <span v-else style="margin-left: 10px">{{ bankItem.summary }}</span>
              </td>
              <td class="top_right_btns">
                <el-tooltip content="新增" placement="top" >
                  <i class="iconfont icon-jiahao" @click="handleAdd(item,bankItem,index2)"></i>
                </el-tooltip>
                <el-tooltip content="编辑" placement="top" >
                  <i class="iconfont icon-bianji" @click="handleEdit(bankItem,item)"></i>
                </el-tooltip>
                <el-tooltip content="删除" placement="top" v-if="item.items.length > 1">
                  <i class="iconfont icon-shanchu" @click="handleDelete(item,bankItem,index2)"></i>
                </el-tooltip>
              </td>
            </tr>
          </table>
        </section>
      </div>
    </div>
    
    <div class="bottom_style">
      <div class="bottom_button">
        <qzf-button size="small" type="primary" style="margin-right:10px" @success="allSave">
          <el-icon><Finished /></el-icon><span  > 一键全存</span>
        </qzf-button>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
    </el-tabs>
    
    <el-dialog :close-on-click-modal="false"
        v-model="dialogVisible"
        title="批量选择"
        width="30%"
        destroy-on-close
      >
      <el-radio-group style="margin-bottom: 15px;" size="small" v-model="bigBusiness.bType">
        <el-radio :label="1" size="large" border>批量选择</el-radio>
        <el-radio :label="2" size="large" border>批量创建</el-radio>
      </el-radio-group>
      <business-type v-if="bigBusiness.bType == 1" :direction="singleItem" v-model:businessId="bigBusiness.businessTypeId"  v-model:businessTypeName="bigBusiness.summary" v-model:itemId="bigBusiness.subjectId" v-model:fzhsId="bigBusiness.fzhsId" type="bank"></business-type>
      <business-type v-if="bigBusiness.bType == 2" :add="true" :direction="singleItem" v-model:businessId="bigBusiness.businessTypeId"  v-model:businessTypeName="bigBusiness.summary" v-model:itemId="bigBusiness.subjectId" v-model:fzhsId="bigBusiness.fzhsId" type="bank"></business-type>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">取 消</el-button>
          <el-button v-if="bigBusiness.bType == 1" type="primary" @click="changebigBusiness()" size="small">确 定</el-button>
          <el-button v-if="bigBusiness.bType == 2" type="primary" :loading="isLoading" @click="changebigBusinessCj()" size="small">批量创建</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 结算 选择银行账户 -->
    <!-- <el-dialog :close-on-click-modal="false"
      v-model="selectBankDialog"
      title="批量选择"
      width="30%"
      destroy-on-close
    >
      <span style="margin-left:10px;">
        银行账户：
      </span>
      <el-select placeholder="请选择" v-model="bankNameId" @change="changeBank" size="mini">
        <el-option v-for="(item,index) in bankList" :key="index" :label="item.name" :value="item.id" size="mini">
        </el-option>
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="selectBankDialog=false;changeBank();" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog> -->



    <!--  签订协议 -->
    <el-dialog :close-on-click-modal="false"
      v-model="dialogAssianVisible"
      :title=this.bankName
      width="40%"
      destroy-on-close
      append-to-body
    >
      <el-table :data="bankBankList" border>
        <el-table-column property="date" label="银行名称" min-width="150">
          <template #default="scope">
            <span >{{ scope.row.bankName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="协议" min-width="250">
          <template #default="scope">
            <qzf-button @success="getBankInfo()" size="small" type="text">采集</qzf-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogAssianVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 选择电子回单查看方式 -->
    <el-dialog
      v-model="checkPaperTypedialogVisible"
      title="请选择纸张类型"
      width="30%"
      :before-close="handleClose"
    >
      <el-radio-group v-model="this.paperType">
        <el-radio label="A5">A5纸张</el-radio>
        <el-radio label="A4">A4纸张</el-radio>
      </el-radio-group>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="checkPaperTypedialogVisible = false">取消</el-button>
          <el-button type="primary" @click="makeSureCkeck">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>

    <qzf-import-back :hideButton="true" ref="importBck" @success="getList()" @success2="otherBankDialog" from="银行" :id="this.bankNameId"></qzf-import-back>
    <addBank ref="addBank" @success="init('2')"></addBank>
    <voucher-show ref="voucherShow" from="6" @success="getList"></voucher-show>
    <otherBank :bankName='this.bankName' :bankId='this.bankNameId' ref="bankTy" @success="getList"></otherBank>
  </div>
</template>

<script>
import { bankNameList , bankList , saveBank , delBank ,getIcbcDz ,bankPdf } from "@/api/bank"
import { wbList } from "@/api/subject"
import addBank from "../../../setting/components/addBank.vue"
import { getPeriodTime } from "@/utils/fun";
import otherBank from "@/components/import/son/otherBank"
import { oneKeyVoucher } from '@/api/voucher'
import { bcCreateCorpAndSubject } from "@/api/invoice.js"

function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}

export default {
  name:'bank',
  components:{
    addBank,
    otherBank
  },
  data() {
    return {
      activeName:'first',
      bankList:[],
      accountStatus:'',
      otherName:'',
      listQuery:{
        page:1,
        limit:20,
        type: 0,
        bankId:0,
        typeAmount:0  //收入支出
      },
      total:0,
      shows: true,
      contentStyleObj:{}, //高度变化
      bankNameId:'',
      bankName:'',
      list:[],
      wbOptions:[],
      huilv:1,
      deleteLoding:false,
      formLabelWidth:'100px',
      bankAmount: "",
      bankAmountCount: "",
      bankAmountOut: "",
      bankAmountIn: "",
      dialogVisible:false,
      singleItem:'',
      bigBusiness: {
        businessTypeId:0,
        summary:'',
        subjectId:0,
        fzhsId:0,
        bType: 1,
      },
      isLoading:false,
      dialogAssianVisible:false,
      bankBankList:[
        {bankName:'工商银行'}
      ],
      checkPaperTypedialogVisible:false,
      paperType:'',
      rowId:''
      // fromInput: null, // 非空表示是从发票录入的结算而来, 注意:一定要在适当的时候重置为null (其它跳转至此页面, 结算产生的记录保存成功之后,...)
      // selectBankDialog:false
    }
  },
  created() {
    this.contentStyleObj=this.$getHeight(390)
    this.init()
  },
  // watch: {
  //   $route() {
  //     console.log("xxx",this.$route);
  //     if(this.$route.path != "/input/money" || !this.$route.params){
  //       return
  //     }
  //     this.init();
  //   }
  // },
  // beforeRouteLeave(to, from, next) {
  //   console.log(to,from,next,"bank");
  // },
  computed:{
    isAllSelected() {
      return this.list.every(el => {
        return el.checked;
      });
    },
    currentBank() {
      let bank = {};
      this.bankList.some(item => {
        if (item.id == this.bankId) {
          bank = item;
        }
      });
      return bank;
    },
    // 选中的id
    checkedGoodIds() {
      let filterArr = this.list.filter((el,i) => {
        el.i = i
        return el.checked;
      });
      return filterArr.map((el,i) => {
        return el.id + "##" + el.i;
      });
    }
  },
  methods:{
    getDirection(item){
      if(item.id == 0){
        return ""
      }
      if(item.amount < 0){
        return "out"
      }else if(item.amount > 0){
        return "in"
      }else{
        return ""
      }
    },
    init(a){
      bankNameList({}).then(res=>{
        if(a == '2'){
          if(res.data.data.list.length != 0){
            let lastListOne = res.data.data.list[res.data.data.list.length-1]
            this.bankList = res.data.data.list
            this.bankName = lastListOne.name
            this.bankNameId = lastListOne.id
            this.listQuery.bankId = lastListOne.id
            this.getList()
            this.accountStatus = lastListOne.currency
            if(lastListOne.currency == "美元"){
              this.wbOptions.map(item=>{
                if(item.code == "美元"){
                  this.huilv = item.rate
                }
              })
            }
            if(lastListOne.currency == "欧元"){
              this.wbOptions.map(item=>{
                if(item.code == "欧元"){
                  this.huilv = item.rate
                }
              })
            }
            if(lastListOne.currency == "港币"){
              this.wbOptions.map(item=>{
                if(item.code == "港币"){
                  this.huilv = item.rate
                }
              })
            }
            if(lastListOne.currency == "日元"){
              this.wbOptions.map(item=>{
                if(item.code == "日元"){
                  this.huilv = item.rate
                }
              })
            }
            if(lastListOne.currency == "英镑"){
              this.wbOptions.map(item=>{
                if(item.code == "英镑"){
                  this.huilv = item.rate
                }
              })
            }
            if(lastListOne.wbRate != ""){
              this.huilv = Number(lastListOne.wbRate)
              return
            }
            this.bankList.unshift({ name: "新增账户+", id: "add" });
          }else{
            this.bankList.unshift({ name: "新增账户+", id: "add" });
          }
        }else{
          if(res.data.data.list.length !=0){
            this.bankList = res.data.data.list
            this.accountStatus = this.bankList[0].currency
            this.bankNameId = res.data.data.list[0].id
            this.bankName = res.data.data.list[0].name
            this.listQuery.bankId = res.data.data.list[0].id
            this.changeBank(res.data.data.list[0].id)
            // if (this.fromInput && res.data.data.list.length != 1) {
            // // 多个或0个银行账户 需手工选择或增加
            //   this.selectBankDialog = true;
            // }
            this.bankList.unshift({ name: "新增账户+", id: "add" });
          }else{
            // if (this.fromInput && res.data.data.list.length == 0) {
            // // 多个或0个银行账户 需手工选择或增加
            //   this.selectBankDialog = true;
            // }
            this.bankList.unshift({ name: "新增账户+", id: "add" });
          }
        }
      })
      wbList({}).then(res=>{
        this.wbOptions = res.data.data.list
        this.$refs.addBank.init(this.wbOptions)
      })
      // this.fromInput = this.$route.params.settleType
    },
    getList(){
      this.isLoading = true
      bankList(this.listQuery).then(res=>{
        this.isLoading = false
        if(res.data.data.list){
          this.list = res.data.data.list
          this.bankAmountIn = res.data.data.in
          this.bankAmountOut = res.data.data.out
          this.bankAmount = res.data.data.endAmount
          this.list.map(item => {
            // 每一小条
            item.items.map(v => {
              if (!v.businessTypeId) {
                item.edit = true;
                item.show = true;
              }
            });
          });
          this.total = res.data.data.total
        }else{
          this.list=[]
          this.total = 0
        }
      })
    },
    // getJsList(){
    //   if(this.fromInput){
    //     let settleData = JSON.parse(this.$route.params.settleData)
    //     if(this.$route.params.settleType == 'output'){
    //       // 销项
    //       settleData.map(v => {
    //         this.list.unshift({
    //           bankNameId: this.bankNameId,
    //           bankName: this.bankName,
    //           type: 1,
    //           edit: true,
    //           show: true,
    //           from:'manual',
    //           otherName:this.otherName,
    //           items: [
    //             {
    //               transDate: getPeriodTime(),
    //               amount: v.sumAmount,
    //               wb: this.accountStatus,
    //               wbAmount: 0,
    //               businessTypeId:3462,
    //               subjectId:v.subjectId,
    //               type:1,
    //               fzhsId:v.fzhsId,
    //               summary:'收销售款',
    //               rate: this.huilv,
    //             }
    //           ]
    //         });
    //       })
    //       console.log(this.list);
    //     }else if(this.$route.params.settleType == 'income'){
    //       // 进项
    //       settleData.map(v => {
    //         console.log(v);
    //         this.list.unshift({
    //           bankNameId: this.bankNameId,
    //           bankName: this.bankName,
    //           type: 1,
    //           edit: true,
    //           show: true,
    //           from:'manual',
    //           otherName:this.otherName,
    //           items: [
    //             {
    //               transDate: getPeriodTime(),
    //               amount: v.sumAmount,
    //               wb: this.accountStatus,
    //               wbAmount: 0,
    //               businessTypeId:3462,
    //               subjectId:v.subjectId,
    //               type:1,
    //               fzhsId:v.fzhsId,
    //               summary:'收销售款',
    //               rate: this.huilv,
    //             }
    //           ]
    //         });
    //       })
    //     }
    //   }
    // },
    // 选择银行
    changeBank(e){
      if(e == "add"){
        if(this.$checkSettleStatus() != ""){
          this.$message.error(this.$checkSettleStatus())
          return 
        }
        this.$refs.addBank.dialogFormVisible = true
        this.$refs.addBank.init(this.wbOptions)
        this.bankNameId = ""
        this.listQuery.bankId = 0
        this.getList()
        return
      }
      this.bankList.map(v=>{
        if(v.id == e){
          this.accountStatus = v.currency
          this.bankNameId = e
          this.listQuery.bankId = e
          this.bankName = v.name
          if(v.wbRate != ""){
            this.huilv = Number(v.wbRate)
            return
          }
          if(v.currency == "美元"){
            this.wbOptions.map(item=>{
              if(item.code == "美元"){
                this.huilv = item.rate
              }
            })
          }
          if(v.currency == "欧元"){
            this.wbOptions.map(item=>{
              if(item.code == "欧元"){
                this.huilv = item.rate
              }
            })
          }
          if(v.currency == "港币"){
            this.wbOptions.map(item=>{
              if(item.code == "港币"){
                this.huilv = item.rate
              }
            })
          }
          if(v.currency == "日元"){
            this.wbOptions.map(item=>{
              if(item.code == "日元"){
                this.huilv = item.rate
              }
            })
          }
          if(v.currency == "英镑"){
            this.wbOptions.map(item=>{
              if(item.code == "英镑"){
                this.huilv = item.rate
              }
            })
          }
        }
      })
      // if(this.fromInput){
      //   this.getList()
      //   this.getJsList()
      // }else{
        this.getList()
      // }
    },
    // 添加银行
    handleAddBig(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if (!this.bankNameId) {
        this.$message({
          message: "请选择银行",
          type: "error"
        });
        return;
      }
      this.list.unshift({
        bankNameId: this.bankNameId,
        bankName: this.bankName,
        type: 1,
        edit: true,
        show: true,
        from:'manual',
        otherName:this.otherName,
        items: [
          {
            transDate: getPeriodTime(),
            amount: 0,
            wb: this.accountStatus,
            wbAmount: 0,
            rate: this.huilv,
            summary: ""
          }
        ]
      });
    },
    changeWb(row){
      row.wbAmount = Number(row.wbAmount)
      row.amount = Number((row.wbAmount*1 * row.rate).toFixed(2))
    },
    // 添加小条
    handleAdd(item, row, index){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      item.show = true;
      item.edit = true;
      let clone = deepClone(row);
      clone.transDate = row.transDate;
      delete clone.id;
      item.items.splice(index + 1, 0, clone);
    },
    // 编辑小条
    handleEdit(row, item) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      item.show = true;
      item.edit = true;
    },
    // 删除小条
    handleDelete(item, row, index) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      item.show = true;
      item.edit = true;
      item.items.splice(index, 1);
    },
    // 保存
    handleSave(item) { 
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      saveBank([item]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')
          this.getList()
        }
      })
    },
    // 删除银行记录
    handleDeleteBig(item) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$confirm("确认删除该条数据？", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'el-icon-delete'

      }).then(() => {
        delBank([{id: Number(item.id)}]).then(res => {
          if(res.data.msg == 'success'){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.changeBank(String(this.bankId));
          }
        });
      });
    },
    handleDelete(item, row, index) {
      item.show = true;
      item.edit = true;
      item.items.splice(index, 1);
    },
    handleClick(tab) {
      if(tab.props.name == 'second'){
        this.listQuery.type = 2
      }else if (tab.props.name == 'third'){
        this.listQuery.type = 1
      }else {
        this.listQuery.type = 0
      }
      this.getList()
    },
    showVoucher(item){
      this.$refs.voucherShow.getList(item)
    },
    getHandlePingzheng(voucherId) {
      this.$store.dispatch('commons/setParam', {addVoucherId: voucherId})
      this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
      this.$router.push({
        path: "/bookkeeps/addVoucher",
        name: "addVoucher"
      });
    },
    otherBankDialog(e){
      this.$refs.bankTy.dialogFormVisible = true
      this.$refs.bankTy.init(e)
    },
    // 全选、全不选
    allSelect() {
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.list = this.list.map(el => {
        el.checked = checked;
        return el;
      });
    },
    singleSelect(row, index) {
      row.checked = !row.checked;
      this.list.splice(index, 1, row);
    },
    handleDeleteAll(){
      let data = [];
      this.checkedGoodIds.map(item => {
        data.push({ id: item.split('##')[0]*1});
      });
      if (data.length < 1) {
        this.$qzfMessage("请至少选择一条数据",1)
        return;
      }
      this.$confirm("确认删除"+data.length+"条数据？", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'el-icon-delete'
      }).then(() => {
        this.isLoading = true
        delBank(data).then(res => {
        this.isLoading = false

          if(res.data.msg == 'success'){
            this.$qzfMessage("删除成功")
            this.$bus.emit('voucherUpdate')
            this.changeBank(String(this.bankId));
          }
        });
      });
    },
    handleCommand(command) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(command == 'a'){
        oneKeyVoucher({type:'bank'}).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("操作成功")
            this.$bus.emit('voucherUpdate')
            this.getList()
          }
        })
      }
    },
    collectBank(){
      this.dialogAssianVisible = true
    },
    getBankInfo(){
      this.isLoading = true 
      getIcbcDz({id:this.bankNameId}).then(res=>{
        this.isLoading = false 
        this.dialogAssianVisible = false
        if(res.data.msg == "success"){
          this.$qzfMessage("获取成功")
          this.getList()
        }
      })
    },
    
    openCom(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if (!this.bankNameId) {
        this.$message({
          message: "请选择银行",
          type: "error"
        });
        return;
      }
      this.$refs.importBck.handImport()
    },
    allSave(){
      this.isLoading = true
      saveBank(this.list).then(res=>{
      this.isLoading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.$bus.emit('voucherUpdate')

          this.getList()
        }
      })
    },
    //批量补充
    handleCzmuch() {
      let data = [];
      this.checkedGoodIds.map(item => {
        data.push({ id: item.split('##')[1]*1});
      });
      if (data.length < 1) {
        this.$qzfMessage("请至少选择一条数据",1)
        return;
      }
      let zheng = false
      let fu = false
      let tiaoShu = false
      data.map(v=>{
        if(this.list[v.id].amount < 0){
          fu = true
        }else if(this.list[v.id].amount > 0){
          zheng = true
        }else if(!this.list[v.id].id){
          tiaoShu = true
        }
      })
      if(fu && zheng){
        this.$qzfMessage("请选择一个方向进行批量补充!",1)
        return;
      }
      if(tiaoShu){
        this.$qzfMessage("请保存之后再补充!",1)
        return;
      }
      if(fu){
        this.singleItem = 'out'
      }else{
        this.singleItem = 'in'
      }
      this.bcList = data
      this.dialogVisible = true
    },
    changebigBusiness(){
      this.bcList.map(v=>{
        this.list[v.id].items.map(item=>{
          item.businessTypeId = this.bigBusiness.businessTypeId
          item.subjectId = this.bigBusiness.subjectId
          item.fzhsId = this.bigBusiness.fzhsId
          item.summary = this.bigBusiness.summary
        })
      })
      this.$qzfMessage("补充完成")
      this.dialogVisible = false
    },
    changebigBusinessCj(){
      let param = {
        subjectId: this.bigBusiness.subjectId,
        fzhsId: this.bigBusiness.fzhsId,
        items: [],
      }
      this.bcList.map(v=>{
        if(this.list[v.id].otherName){
          param.items.push({
            corpName: this.list[v.id].otherName,
            Id: this.list[v.id].id
          })
        }
      })
      this.isLoading = true
      bcCreateCorpAndSubject(param).then(res=>{
        this.isLoading = false
        this.dialogVisible = false
        if(res.data.msg != "success"){
          return
        }
        this.$store.dispatch("commons/getSubjects")
        this.$store.dispatch("commons/getBusinessType")
        let list = res.data.data.list
        list.map(l=>{
          this.bcList.map(v=>{
            if(this.list[v.id].id == l.id){
              this.list[v.id].items.map(item=>{
                item.businessTypeId = this.bigBusiness.businessTypeId
                item.subjectId = l.subjectId
                item.fzhsId = l.fzhsId
                item.summary = this.bigBusiness.summary
              })
            }
          })
        })
      })
    },
    checkBankPdf(row){
      this.checkPaperTypedialogVisible = true
      this.rowId = row.id
    },
    makeSureCkeck(){
      let param = {
        id:this.rowId,
        paperType:this.paperType
      }
      bankPdf(param).then(res => {
        if(res.data.msg == "success"){
          this.checkPaperTypedialogVisible = false
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bank-item-container {
  margin-bottom: 10px;
  border: 1px solid #c2c2c2;
  border-bottom: 0 !important;
}
.bank-item-header {
  width: 100%;
  background: #f6f6f6;
  box-sizing: border-box;
  padding-left: 20px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  // display: flex;
  position: relative;
  border-bottom: 1px solid #c2c2c2;
  > div {
    margin-right: 30px;
    display: inline-block;
  }
  .item-input {
    position: absolute;
    left: 5px;
    zoom: 110%;
    top: 50%;
    transform: translateY(-50%);
  }
}
.dfyhm{
  cursor: pointer;
  border: 1px solid #c2c2c2;
  border-radius: 2px;
  outline: none;
  width: 250px;
  line-height: 20px;
  margin-top: 6px;
}
.bank-item-header-caozuo {
  float: right;
  margin-right: 15px;
  i {
    margin: 0 5px;
    cursor: pointer;
    // color: $color-main;
    font-size: 18px;
    line-height: 20px;
  }
}
.item_box{
  width: 100%;
  font-size: 10px;
  tr{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    td{
      flex: 1;
      line-height: 34px;
      border-color: #c2c2c2!important;
      border-bottom: 1px solid #c2c2c2;
      border-right: 1px solid #c2c2c2;
      padding: 0 10px;
      &:last-child{
        border-right: none;
      }
    }
  }
}
.top_right_btns {
  float: right;
  // width: 22%;
  // margin-right: 15px;
  text-align: center;
  i {
    margin: 0 5px;
    cursor: pointer;
    color: $color-main;
    font-size: 18px;
    line-height: 24px;
  }
}
.bank-table-header {
  background: #eee;
  position: relative;
  border: 1px solid #c2c2c2;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  height: 36px;
  line-height: 34px;
  font-size: 14px;
  > div {
    border-right: 1px solid #c2c2c2;
    text-align: center;
    flex: 1;
  }
  > div:last-child{
    border-right: 0;
  }
  input {
    position: absolute;

    zoom: 120%;
    left: 5px;
    top: 50%;
    transform: translateY(-50%) !important;
  }
}
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    // float: right;
    text-align: right;
  }
}
</style>