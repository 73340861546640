<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="设置账户" v-model="dialogFormVisible" width="23%">
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <el-form-item label="账户简称：" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" autocomplete="off" size="small" style="width:170px"></el-input>
      </el-form-item>
      <el-form-item label="币种：" :label-width="formLabelWidth">
        <el-select filterable v-model="form.currency" placeholder="请选择" size="small" style="width:170px">
          <el-option
            v-for="item in wbOptions"
            :label="item.type"
            :value="item.code"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="对应科目：" :label-width="formLabelWidth" prop="subjectId">
        <subject-list :width="170" :subjectName="form.name" v-model:subjectId="form.subjectId" v-model:fzhsItemId="form.fzhsId" :codes="$findCode(['code1002'])"></subject-list>
      </el-form-item>
      <el-form-item label="银行主账号：" :label-width="formLabelWidth" prop="bankNo">
        <el-input v-model="form.bankNo" autocomplete="off" size="small" style="width:170px"></el-input>

      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveBank" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { saveBankName } from "@/api/bank"
export default {
  name:'addBank',
  data(){
    return{
      dialogFormVisible:false,
      form:{

      },
      wbOptions: [],
      formLabelWidth: '100px',
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        subjectId: [
          { required: true, message: '请选择科目', trigger: 'change' },
        ],
      },
    }
  },
  created(){
  },
  methods:{
    init(list) {
      this.wbOptions = list
    },
    saveBank(){
      saveBankName(this.form).then(res=>{
        if(res.data.msg == "success"){
          this.dialogFormVisible = false
          this.$emit('success')
          this.$qzfMessage('操作成功')
        }
      })
    },
    bianji(row){
      if(row == 'add'){
        this.form = {}
      }else{
        this.form = row
      }
      this.dialogFormVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select{
  margin-left:0px
}
</style>